import React from 'react';
import SudokuGame from "../features/sudoku/SudokuGame";

function HomePage() {
    return (
        <div>
            <SudokuGame />
        </div>
    );
}

export default HomePage;
