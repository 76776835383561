import React from 'react';

function Navbar() {
    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
            {/* Use a container to align the navbar content with the main content */}
            <div className="container">
                <a className="navbar-brand" href="/#">
                    <span style={{ fontWeight: 'bold', color: '#1c1c1c' }}>Sudoku </span>
                    <span style={{ fontWeight: 'lighter', color: '#1c1c1c' }}>Challenge</span>
                </a>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav"
                        aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                {/*<div className="collapse navbar-collapse" id="navbarNav">*/}
                {/*    <ul className="navbar-nav ml-auto"> {*/}
                {/*        <li className="nav-item">*/}
                {/*            <a className="nav-link" href="/">Home</a>*/}
                {/*        </li>*/}
                {/*        <li className="nav-item">*/}
                {/*            <a className="nav-link" href="/about">About</a>*/}
                {/*        </li>*/}
                {/*    </ul>*/}
                {/*</div>*/}
            </div>
        </nav>
    );
}

export default Navbar;
