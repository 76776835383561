import React from 'react';
import SudokuCell from './SudokuCell';

function SudokuBoard({puzzle, onValueChange, onHandleSelected, isNumberComplete, disabled, isCellValid}) {

    return (
        <div className={`container ${disabled ? 'disabled' : ''}`}>
            <div className="sudoku-board">
                {puzzle.map((row, rowIndex) => (
                    row.map((cell, cellIndex) => (
                        <SudokuCell
                            key={`${rowIndex}-${cellIndex}`}
                            cell={cell}
                            onValueChange={onValueChange}
                            onHandleSelected={onHandleSelected}
                            isNumberComplete={isNumberComplete}
                            isCellValid={isCellValid}
                        />
                    ))
                ))}
            </div>
        </div>
    );
}

export default SudokuBoard;