import React from "react";
import {SudokuLevels} from "../../util/Constants";

function SudokuControls({onValueChange, onNewGame, onClearCell, details, isNumberComplete, disabled}) {

    return (
        <div className="container">
            <div className="row mb-3">
                <div key="level" className="col-4">
                    <span style={{ fontWeight: 'bold', color: '#1c1c1c' }}>Level: </span>
                    <span style={{ fontWeight: 'lighter', color: '#1c1c1c' }}> {`${details.level}`}</span>
                </div>
                <div key="missingNumber" className="col-4">
                    <span style={{ fontWeight: 'bold', color: '#1c1c1c' }}>Missing: </span>
                    <span style={{ fontWeight: 'lighter', color: '#1c1c1c' }}> {`${details.missingNumbers}`}</span>
                </div>
                <div key="mistakes" className="col-4">
                    <span style={{ fontWeight: 'bold', color: '#1c1c1c' }}>Mistakes: </span>
                    <span style={{ fontWeight: 'lighter', color: '#1c1c1c' }}> {`${details.mistakesCount}/${details.maxMistakesAllowed}`}</span>
                </div>
            </div>
            <div className="row mb-3">
                {Array.from({length: 9}, (_, i) => (
                    <div className="col-1 col-md-4" key={i}>
                        <button
                            key={i}
                            className={`btn btn-steel-blue w-100 mr-1 mb-1`}
                            onClick={() => onValueChange(i + 1)} disabled={isNumberComplete(i + 1)}>
                            {i + 1}
                        </button>
                    </div>
                ))}
            </div>
            <div className="row">
                <div className="col-md-6 col-xs-6">
                    <div className="btn-group w-100">
                        <button type="button" className="btn btn-primary dropdown-toggle" data-bs-toggle="dropdown"
                                aria-expanded="false">
                            New Game
                        </button>
                        <ul className="dropdown-menu w-100">
                            <li><a className="dropdown-item" href="/#" onClick={() => { onNewGame(SudokuLevels.BEGINNER); }}>Beginner</a></li>
                            <li><a className="dropdown-item" href="/#" onClick={() => { onNewGame(SudokuLevels.EASY); }}>Easy</a></li>
                            <li><a className="dropdown-item" href="/#" onClick={() => { onNewGame(SudokuLevels.MEDIUM); }}>Medium</a></li>
                            <li><a className="dropdown-item" href="/#" onClick={() => { onNewGame(SudokuLevels.HARD); }}>Hard</a></li>
                            <li><a className="dropdown-item" href="/#" onClick={() => { onNewGame(SudokuLevels.EXPERT); }}>Expert</a></li>
                        </ul>
                    </div>
                </div>
                <div className={`col-md-6 col-xs-6 ${disabled ? 'disabled' : ''}`}>
                    <button key="clearSelectedCell" className="btn btn-dark w-100" onClick={() => onClearCell()}>Clear
                    </button>
                </div>
            </div>
        </div>
    );

}

export default SudokuControls;