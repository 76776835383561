import React from 'react';

function SudokuCell({cell, onValueChange, onHandleSelected, isNumberComplete, disabled, isCellValid}) {

    // Function to handle value change from keyboard input
    const handleKeyPress = (e) => {
        if (disabled) {
            return;
        }

        const number = parseInt(e.key, 10);
        if (cell.selected && !cell.clue && !isNumberComplete(number)) {
            onValueChange(number);
        }
    };

    const handleOnClick = () => {
        if (disabled) {
            return;
        }

        onHandleSelected(cell);
    }

    const value = cell.clue ? cell.number : (cell.number === 0 ? '' : cell.number);

    let classCell = "sudoku-cell";
    if (disabled) {
        classCell += " " + disabled ? "cell-disabled" : "";
    } else {
        classCell += " " + (cell.clue ? 'clue' : '')
            + " " + (cell.selected ? "selected" : (cell.sectionSelected ? "section-selected" : ""))
            + " " + (isCellValid(cell) ? "" : "incorrect-answer");
    }

    const externalClassCell = "sudoku-external-cell"
        + " " + (cell.posY % 3 === 2 && cell.posY !== 8 ? "border-right-col" : "")
            + " " + (cell.posX % 3 === 2 && cell.posX !== 8 ? "border-bottom-row" : "");

    return (
        <div key={`${cell.posX}-${cell.posY}`} className={externalClassCell}>
            <div
                tabIndex="0"
                className={classCell}
                onClick={handleOnClick}
                onKeyDown={handleKeyPress}>
                {value}
            </div>
        </div>
    );
}

export default SudokuCell;