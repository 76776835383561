import React from 'react';
import { Modal, Button } from 'react-bootstrap';

function EndGame({ show, success, handleClose }) {

    const handleNewGame = () => {

        handleClose();
    }

    return (
        <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} centered>
            <Modal.Header closeButton>
                <Modal.Title>{success ? "Congratulations!" : "Game over!"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>{success ? "You solved it. Nice job!" : "Not this time :'("}</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={() => {
                    handleNewGame();
                }}>
                    Play Again
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default EndGame;
