import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from "./pages/HomePage";
import AboutPage from "./pages/AboutPage";
import Layout from "./components/Layout";

function App() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Layout />}>
                    {/* Nesting routes inside Layout */}
                    <Route index element={<HomePage />} /> {/* Use index for root route */}
                    <Route path="about" element={<AboutPage />} />
                    {/* Add more nested routes as needed */}
                </Route>
            </Routes>
        </Router>
    );
}

export default App;
