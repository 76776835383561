import React from 'react';

function MainLayout({ left, center, right }) {
    return (
        <div className="container my-5">
            <div className="row">
                <div className="col-12 col-md-2 col-lg-1">
                    {left}
                </div>
                <div className="col-12 col-md-8 col-lg-10">
                    {center}
                </div>
                <div className="col-12 col-md-2 col-lg-1">
                    {right}
                </div>
            </div>
        </div>
    );
}

export default MainLayout;
