// src/components/Footer.js
import React from 'react';

function Footer() {
    return (
        <footer className="mt-auto py-3 bg-light">
            <div className="container text-center">
                <span className="text-muted">&copy; 2024 Sudoku Challenge. All rights reserved.</span>
            </div>
        </footer>
    );
}

export default Footer;
