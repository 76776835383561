import axios from 'axios';

// Get the base URL from environment variables
const ApiService = {
    fetchNewGame(level, username) {
        const params = new URLSearchParams();
        params.append('level', level);
        if (username) {
            params.append('username', username);
        }
        return axios.get(`/api/game/?${params.toString()}`);
    },
    endGame(game) {
        return axios.post(`/api/game/endgame/`, game);
    }
};

export default ApiService;

