import React from 'react';
import Navbar from './Navbar';
import Footer from './Footer';
import MainLayout from "./MainLayout";
import {Outlet} from "react-router-dom";
import LeftContent from "./LeftContent";
import RightContent from "./RightContent";

function Layout() {
    return (
        <div className="d-flex flex-column min-vh-100">
            <Navbar />
            <div class="container flex-fill">
                <MainLayout
                    left={<LeftContent/>}
                    center={<Outlet />}
                    right={<RightContent />}
                />
            </div>
            <Footer />
        </div>
    );
};

export default Layout;
